import imgTma from '../images/Tma.png';
import imgSds from '../images/Sds.png';
import imgPortfolio from '../images/Portfolio.png';
import imgTIFF from '../images/TIFF-home.png';
import imgTIFFS from '../images/TIFF-schedule.png';
import imgKKampus from '../images/Kinderkampus.png';
import imgRPMX from '../images/RPMX.png';
import imgTEasy from '../images/Techeasy.png';
import imgFCircuit from '../images/TIFF-filmcircuit.png';


const ProjectsList = [
    { title: 'TIFF', languages: 'ReactJS|Laravel', url: 'https://tiff.net/', image: imgTIFF, imageAlt: 'TIFF homepage',
        notes: 'Currently working as a Lead Developer on various projects for Toronto International Film Festival.' 
    },
    { title: 'TIFF Schedule', languages: 'ReactJS|Laravel', url: 'https://tiff.net/tiff2022', image: imgTIFFS, imageAlt: 'TIFF schedule', 
        notes: 'Built festival schedule for TIFF 2021 and TIFF 2022.' 
    },
    { title: 'RPMX', languages: 'C#|.Net', url: 'https://rpmx.io/Index', image: imgRPMX, imageAlt: 'RPMX homepage', 
        notes: 'Restyled website and worked on login/register functionality.' 
    },
    { title: 'Portfolio', languages: 'ReactJS|NodeJS', url: 'https://harmank.com', image: imgPortfolio, imageAlt: 'Harman website', 
        notes: 'This portfolio site was built using ReactJS, MaterialUI and NodeJS.' 
    },
    { title: 'Film Circuit', languages: 'C#|.Net', url: 'https://filmcircuit.tiff.net/', image: imgFCircuit, imageAlt: 'TIFF Film circuit homepage', 
        notes: 'Worked on AODA errors and updated login/register functionality.' 
    },
    { title: 'Kinder Kampus', languages: 'PHP|Wordpress', url: 'https://kinderkampuson.ca/', image: imgKKampus, imageAlt: 'Kinder kampus homepage', 
        notes: 'Worked on updating the styling and functionality of various pages.' 
    },
    { title: 'Tech Easy', languages: 'PHP|Wordpress', url: 'https://techeasy.ca/', image: imgTEasy, imageAlt: 'Tech Easy homepage', 
        notes: 'Rebuilt the homepage and membership pages and updated styling' 
    },
    { title: 'Talent Insight', languages: 'CSS|JQuery', url: 'https://www.talentinsight.org/account/login', image: imgTma, imageAlt: 'Talent Insight login', 
        notes: 'This website was built by Trigbit Technologies. I was part of the Front-end development team.' 
    },
    { title: 'FCT Companies', languages: 'CSS|JQuery', url: 'https://sds.fctcompanies.com/', image: imgSds, imageAlt: 'FCT companies', 
        notes: 'This website was built by Trigbit Technologies. I was part of the Front-end development team.' 
    },
];

export default ProjectsList;
