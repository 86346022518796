import React from 'react';
import {
    Grid,
    Card,
    CardActionArea,
    CardActions,
    CardMedia,
    CardContent,
    Button,
    Typography
} from '@material-ui/core';

const ProjectItem = ({ project, classes }) => {

    return (
        <Grid item xs={12} sm={8} md={6} lg={4}>
            <Card className={classes.cardContainer}>
                <CardActionArea>
                    <CardMedia
                        className={classes.cardImage}
                        component="img"
                        alt={project.imageAlt}
                        height="250"
                        image={project.image}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" style={{ color: "#122254", textAlign: "center" }}>
                            {project.title}
                        </Typography>
                        <Typography variant="body1" color="textSecondary" component="p" style={{ color: "#122254", textAlign: "center" }}>
                            {project.languages}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" style={{ color: "#122254" }}>
                            {project.notes}
                        </Typography>
                    </CardContent>
                    <CardActions style={{ justifyContent: 'center' }}>
                        <Button size="large" target="_blank" className={classes.projectItemButton} href={project.url}>
                            Demo
                        </Button>
                    </CardActions>
                </CardActionArea>
            </Card>
        </Grid>
    )
}

export default ProjectItem;