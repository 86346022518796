import React, {useState} from 'react';
import axios from 'axios';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { TextField, Button, Grid, Box, Typography } from '@material-ui/core';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import SendIcon from '@material-ui/icons/Send';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Navbar from './navbar';
import Footer from './footer';


const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({

  form: {
    margin: "1.7rem",
  },

  textContainer: {
    margin: "1rem",
    width: "70%",
    [theme.breakpoints.up('sm')]: {
      width: "40%",
    }
  },

  email: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#5e7cd6",
    background: "#222",
    border: "1px solid #122254",
    borderRadius: "10px",
    boxShadow: "10px 10px 20px 0px rgba(18,34,84,1)",
  },

  emailLink: {
    padding: "0.3rem 0.4rem 0 0",
    color: "#5e7cd6",
    textDecoration: "none",
  },

  emailIcon: {
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "rotate(-25deg)"
    }
  },

  button: {
    marginTop: "1rem",
    color: "#122254",
    border: "1px solid #122254",
    boxShadow: "10px 10px 20px 0px rgba(18,34,84,1)",
    backgroundColor: "#5e7cd6",
    "&:hover": {
      backgroundColor: "#222",
      color: "#5e7cd6"
    }
  }
}))

const InputField = withStyles({
  root: {
    "& container": {
      paddingBottom: "100px",
    },

    "& label.Mui-focused": {
      color: "#122254",
    },
    "& label": {
      color: "#122254"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #122254",
        boxShadow: "10px 10px 20px 0px rgba(18,34,84,1)"
      }
    }
  }
})(TextField); 


const Contact = () => {
  const [state, setState] = useState({
    name: "",
    email: "",
    company: "",
    message: ""
  });

  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);

  const handleSuccessAlert = () => {
    setSuccessAlert(true);
  };
  const handleErrorAlert = () => {
    setErrorAlert(true);
  };

  const handleClose = () => {
    setSuccessAlert(false);
    setErrorAlert(false);
  };

  const handleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value})
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const newMessage = {
      name: state.name,
      email: state.email,
      company: state.company,
      message: state.message
    }

    await axios.post('/send', newMessage)
      .then(res => handleSuccessAlert())
      .then(res => {setTimeout(()=>{ window.location = "/"}, 1500)})
      .catch(err => { console.log('Error:', err); handleErrorAlert(); })
  };

  const classes = useStyles();
  return (
    <>
      <Navbar heading="Contact Me"/>
      <Box component="div">
        <Grid container justify="center">
          <Box component="form" onSubmit={handleSubmit} className={classes.form}>
            <Typography variant="h5" style={{color: "#122254", textAlign: "center"}}>
              Send a Message
            </Typography>
            <InputField onChange={handleChange} required={true} fullWidth={true} label="Name" name="name" variant="outlined"
              inputProps={{style:{color: "#122254"}}} margin="normal" size="medium" />
            <InputField onChange={handleChange} fullWidth={true} required={true} label="Email" name="email" variant="outlined" 
              inputProps={{style:{color: "#122254"}}} margin="normal" size="medium" />
            <InputField onChange={handleChange} fullWidth={true} label="Company (optional)" name="company" variant="outlined" 
              inputProps={{style:{color: "#122254"}}} margin="normal" size="medium" />
            <InputField onChange={handleChange} required={true} multiline fullWidth={true} label="Message" name="message" 
              variant="outlined" inputProps={{style:{color: "#122254"}}} margin="normal" size="medium" />
            <br/>
            <Button className={classes.button} type="submit" variant="outlined" fullWidth={true} endIcon={<SendIcon/>}>
              Send
            </Button>
            <Snackbar open={successAlert} autoHideDuration={4000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Your message has been sent!
              </Alert>
            </Snackbar>
            <Snackbar open={errorAlert} autoHideDuration={4000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="error">
                Message could not be sent. Please try again or use email instead.
              </Alert>
            </Snackbar>
          </Box>
        </Grid>

        <Grid container justify="center">
          <Box component="div" className={classes.textContainer}>
            <Typography variant="h5" style={{color: "#122254", textAlign: "center"}}>
              Or Email me at
            </Typography>
            <Typography variant="subtitle1" align="center" className={classes.email} >
              <a href={"mailto:harmandeepkaul@gmail.com"} className={classes.emailLink}>
              <MailOutlineIcon className={classes.emailIcon}/> </a> Harmandeepkaul@gmail.com
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Footer position="fixed"/>
    </>
  )
}

export default Contact
