import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid
} from '@material-ui/core';
import Navbar from './navbar';
import Footer from './footer';
import ProjectsList from './projectList';
import ProjectItem from './projectItem';

const useStyles = makeStyles({
  mainContainer: {
    background: "none",
    height: "100%"
  },
  cardContainer: {
    background: "#5e7cd6",
    border: "2px solid #122254",
    boxShadow: "10px 10px 20px 0px rgba(18,34,84,1)",
    maxWidth: 345,
    margin: "4rem auto"
  },
  cardImage: {
    padding: "0.5rem",
    transition: "0.2s",
    "&:hover": {
      padding: "0.2rem"
    }
  },
  projectItemButton: {
    color: "#C3CCEA", 
    background: "#122254", 
    marginBottom: '16px',
    "&:hover": {
      outline: "1px solid #122254",
      color: "#000"
    }
  }
})

const Projects = () => {
  const classes = useStyles();
  return (
    <>
      <Box component="div" className={classes.mainContainer}>
        <Navbar heading="Projects" />
        <Grid container justify="center">
          {ProjectsList.map(p => (
            <ProjectItem project={p} classes={classes} />
          ))}
        </Grid>
      </Box>
      <Footer position="relative" />
    </>
  )
}

export default Projects;