import React from 'react';
import Particles from 'react-particles-js'
import Logo1 from '../logos/R-logo.png';
import Logo2 from '../logos/Node.js_logo.png';
import Logo3 from '../logos/Laravel_logo.png';
import Logo4 from '../logos/JavaScript_logo.png';
import Logo5 from '../logos/mysql_logo.png';
import Logo6 from '../logos/php_logo.png';

const ParticleBar = () => {
  return (
    <Particles
        params={{
          particles: {
              number: {
                  value: 6,
                  density: {
                      enable: true,
                      value_area: 800
                  }
              },
              line_linked: {
                  enable: false
              },
              move: {
                  speed: 4,
                  out_mode: "out"
              },
              shape: {
                  type: [
                      "image",
                      "circle"
                  ],
                  image: [
                      {
                          src: Logo1,
                          height: 20,
                          width: 30
                      },
                      {
                          src: Logo2,
                          height: 20,
                          width: 30
                      },
                      {
                          src: Logo4,
                          height: 20,
                          width: 20
                      },
                      {
                          src: Logo3,
                          height: 20,
                          width: 30
                      },
                      {
                          src: Logo5,
                          height: 30,
                          width: 30
                      },
                      {
                          src: Logo6,
                          height: 20,
                          width: 30
                      }
                  ]
              },
              color: {
                  value: "#6B85D4"
              },
              size: {
                  value: 30,
                  random: false,
                  anim: {
                      enable: true,
                      speed: 6,
                      size_min: 10,
                      sync: false
                  }
              },
              opacity: {
                value: 0.8
              }
          },
          retina_detect: false
        }} 
      />
  )
}

export default ParticleBar;
